<template>
	<transition name="fade-in-up">
		<router-view></router-view>
	</transition>
</template>

<script>
export default {
	components: {}
};
</script>
